
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    question: { type: String, required: true, default: undefined },
    answer: { type: String, required: true, default: undefined },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
})
