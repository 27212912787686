import { render, staticRenderFns } from "./QuestionAndAnswer.vue?vue&type=template&id=5e6ff42a&scoped=true"
import script from "./QuestionAndAnswer.vue?vue&type=script&lang=js"
export * from "./QuestionAndAnswer.vue?vue&type=script&lang=js"
import style0 from "./QuestionAndAnswer.vue?vue&type=style&index=0&id=5e6ff42a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e6ff42a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconChevronDown: require('/home/userapp/components/Atom/Icon/IconChevronDown.vue').default,SmoothReflow: require('/home/userapp/components/Global/SmoothReflow.vue').default})
